import { graphql, Link, PageProps } from 'gatsby';
import * as React from 'react';

import Shell from '../components/_layout/shell';
import { InView } from '../components/inView';
import { Ticker } from '../components/ticker';

import LOGO from '../assets/AO_LOGO_ORANGE.svg';

import * as styles from './index.module.scss';

const Index: React.FC<PageProps<Queries.WorkPageQuery>> = ({ data }) => {
  return (
    <Shell>
      <div className={styles.work}>
        <div
          className={styles.placeholder}
          style={{ backgroundImage: `url(${data?.prismicWorkPage?.data?.video?.thumbnail_url})` }}
        />
        {data.prismicWorkPage?.data.video?.embed_url && (
          <div className={styles.video}>
            <script src="https://player.vimeo.com/api/player.js"></script>
            <iframe
              src={data.prismicWorkPage?.data.video?.embed_url}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
        )}
        <div className={styles.header}>
          <nav className={styles.nav}>
            <InView delay={600}>
              <Link to="/" activeClassName={styles.active}>
                Home
              </Link>
            </InView>
            <InView delay={650}>
              <Link to="/work" activeClassName={styles.active}>
                Work
              </Link>
            </InView>
            <InView delay={700}>
              <Link to="/go-all-out" activeClassName={styles.active}>
                Go AllOut
              </Link>
            </InView>
          </nav>

          <InView delay={900} className={styles.headerLogo}>
            AllOut
          </InView>
        </div>

        <div className={styles.hero}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={LOGO} />
          </div>
        </div>
        <div className={styles.tick}>
          <Ticker tickerText={data.prismicHomePage?.data?.banner_content?.richText} />
        </div>
      </div>
    </Shell>
  );
};

export const pageQuery = graphql`
  query WorkPage {
    prismicWorkPage {
      data {
        video {
          embed_url
          thumbnail_url
        }
      }
    }
    prismicHomePage {
      data {
        banner_content {
          richText
        }
      }
    }
  }
`;

export default Index;
