import * as React from 'react';

import * as styles from './ticker.module.scss';

interface IProps {
  tickerText: any;
}
export const Ticker: React.FC<IProps> = ({ tickerText }) => {
  const mappedtickerText = tickerText.map(rt => rt.text);
  const bannerContent = [...mappedtickerText, ...mappedtickerText].join(' - ');

  return (
    <div className={styles.tickerContainer}>
      <div className={styles.tickerWrapper}>
        <div className={styles.ticker}>{bannerContent}</div> - <div className={styles.ticker}>{bannerContent}</div>
      </div>
    </div>
  );
};
